import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import ms from 'ms';
import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import trpc from '@/lib/trpc';

const footerNavigation = {
  bottomLinks: [
    { href: '#', name: 'Accessibility' },
    { href: '#', name: 'Privacy' },
    { href: '#', name: 'Terms' },
  ],
  company: [
    { href: '/o-nas', name: 'O nás' },
    { href: '/stranky/o-bunzl', name: 'O Společnosti BUNZL' },
    { href: '/kontakty', name: 'Kontakt' },
    { href: '/kontaktni-formular', name: 'Kontaktní formulář' },
    { href: '/stranky/kariera', name: 'Kariéra' },
    { href: '/obchodni-tym', name: 'Obchodní tým' },
    { href: '/zakaznicky-servis', name: 'Zákaznický servis' },
  ],
  legal: [
    { href: '/stranky/obchodni-podminky', name: 'Obchodní podmínky' },
    { href: '/stranky/bezpecnostni-informace', name: 'Bezpečnostní informace' },
    { href: '/stranky/zasady-ochrany-osobnich-udaju', name: 'Zásady ochrany osobních údajů' },
    { href: '/stranky/zasady-pouzivani-souboru-cookies', name: 'Zásady používání souboru cookies' },
    // { href: '/stranky/zasady-ochrany-osobnich-udaju-zamestnanci', name: 'Zásady ochrany osobních údajů zaměstnanci' },
  ],
  other: [
    { href: '/stranky/certifikaty', name: 'Certifikáty' },
    { href: '/stranky/velikostni-tabulky', name: 'Velikostní tabulky' },
    { href: '/stranky/normy', name: 'Normy' },
    { href: '/stranky/piktogramy', name: 'Piktogramy' },
    { href: '/stranky/udrzitelnost', name: 'Udržitelnost' },
  ],
  services: [
    { href: '/stranky/vydejni-automaty', name: 'Výdejní automaty' },
    { href: '/stranky/branding-odevu', name: 'Branding oděvů' },
    { href: '/stranky/moznosti-prepravy', name: 'Možnosti přepravy' },
    { href: '/stranky/prace-ve-vyskach', name: 'Řešení pro práci ve výškách a nad volnou hloubkou' },
  ],
};

export default function LayoutBaseFooter() {
  return (
    <footer aria-labelledby='footer-heading' className='bg-surface print:hidden'>
      <h2 className='sr-only' id='footer-heading'>
        {'Patička'}
      </h2>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div className='border-surface-divid border-t'>
          <div className='pt-16 pb-20'>
            <div className='md:flex md:justify-center'>
              <Image alt='' className='h-8 w-auto' height={50} src='/images/logo/logo_200x50.webp' width={200} />
            </div>

            <div className='mx-auto mt-16 max-w-5xl xl:grid xl:grid-cols-2 xl:gap-8'>
              <div className='grid grid-cols-2 gap-8 xl:col-span-2'>
                <div className='space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0'>
                  <div>
                    <h3 className='text-content-focus text-sm font-semibold'>Společnost</h3>
                    <ul className='mt-6 space-y-2'>
                      {footerNavigation.company.map((item) => (
                        <li key={item.name} className='text-sm'>
                          <a className='text-content hover:text-primary-focus' href={item.href}>
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h3 className='text-content-focus text-sm font-semibold'>Služby</h3>
                    <ul className='mt-6 space-y-2'>
                      {footerNavigation.services.map((item) => (
                        <li key={item.name} className='text-sm'>
                          <a className='text-content hover:text-primary-focus' href={item.href}>
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className='space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0'>
                  <div>
                    <h3 className='text-content-focus text-sm font-semibold'>Ostatní</h3>
                    <ul className='mt-6 space-y-2'>
                      {footerNavigation.other.map((item) => (
                        <li key={item.name} className='text-sm'>
                          <a className='text-content hover:text-primary-focus' href={item.href}>
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h3 className='text-content-focus text-sm font-semibold'>Legislativa</h3>
                    <ul className='mt-6 space-y-2'>
                      {footerNavigation.legal.map((item) => (
                        <li key={item.name} className='text-sm'>
                          <a className='text-content hover:text-primary-focus' href={item.href}>
                            {item.name}
                          </a>
                        </li>
                      ))}
                      <li key='open_cookie_preferences' className='text-sm'>
                        <a className='text-content hover:text-primary-focus' href='#' id='open_preferences_center'>
                          Nastavení souborů cookie
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='mx-auto mt-16 max-w-5xl xl:grid xl:grid-cols-2 xl:gap-8'>
              <div className='grid grid-cols-2 gap-8 xl:col-span-2'>
                <div className='space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0'>
                  <div>
                    <h3 className='text-content-focus text-sm font-semibold'>Společnost</h3>
                    <ul className='mt-6 space-y-6'>
                      {footerNavigation.company.map((item) => (
                        <li key={item.name} className='text-sm'>
                          <a className='text-content hover:text-primary-focus' href={item.href}>
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className='space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0'>
                  <div>
                    <h3 className='text-content-focus text-sm font-semibold'>Legislativa</h3>
                    <ul className='mt-6 space-y-6'>
                      {footerNavigation.legal.map((item) => (
                        <li key={item.name} className='text-sm'>
                          <a className='text-content hover:text-primary-focus' href={item.href}>
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {/* <div className='pb-4 md:flex md:items-center md:justify-between'>
          <div className='flex justify-center space-x-6 md:order-2'>
            <Link
              className='text-content-suspend hover:text-content'
              href={'https://www.facebook.com/blyth.cz/'}
              prefetch={false}
              target='_blank'
            >
              <span className='sr-only'>{'Facebook'}</span>
              <svg aria-hidden='true' className='h-6 w-6' fill='currentColor' viewBox='0 0 24 24'>
                <path
                  clipRule='evenodd'
                  d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
                  fillRule='evenodd'
                />
              </svg>
            </Link>
            <Link className='text-content-suspend hover:text-content' href={'/'} prefetch={false} target='_blank'>
              <span className='sr-only'>{'Instagram'}</span>
              <svg aria-hidden='true' className='h-6 w-6' fill='currentColor' viewBox='0 0 24 24'>
                <path
                  clipRule='evenodd'
                  d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'
                  fillRule='evenodd'
                />
              </svg>
            </Link>
          </div>
          <div className='mt-8 md:order-1 md:mt-0'>
            <p className=' text-center text-sm leading-5'>
              <a className='text-content-suspend hover:text-content' href='tel:+420272099544'>
                <PhoneIcon aria-hidden='true' className='inline-block h-4 w-4 text-current' />
                <span className='ml-2'>{'+420 272 099 544'}</span>
              </a>
              <a className='text-content-suspend hover:text-content ml-8' href='mailto:prodej@blyth.cz'>
                <EnvelopeIcon aria-hidden='true' className='inline-block h-4 w-4 text-current' />
                <span className='ml-2'>{'prodej@blyth.cz'}</span>
              </a>
            </p>
          </div>
        </div> */}

        <div>
          <div className='lg:grid lg:grid-cols-2 lg:gap-x-6 xl:gap-x-8'>
            <div className='bg-secondary flex items-center rounded-lg p-6 sm:p-10'>
              <div className='mx-auto max-w-md'>
                <h3 className='text-secondary-contrast text-xl font-medium'>Přihlašte se k odběru novinek</h3>
                <p className='text-content-suspend mt-2 text-base'>
                  Nejnovější informace, zprávy, produkty přímo do vašeho mailboxu každý týden.
                </p>
                <div className='mt-4 sm:mt-6'>
                  <LayoutBaseFooterNewsletterRegisration />
                </div>
              </div>
            </div>
            <div className='relative mt-6 flex items-center py-12 px-6 sm:py-16 sm:px-10 lg:mt-0'>
              <div className='absolute inset-0 overflow-hidden rounded-lg'>
                {/* <Image
                  alt=''
                  className='h-full w-full object-cover object-center saturate-0'
                  fill
                  src='https://tailwindui.com/img/ecommerce-images/footer-02-exclusive-sale.jpg'
                  unoptimized
                /> */}
                <div className='bg-primary absolute inset-0' />
              </div>
              <div className='relative mx-auto max-w-md text-center'>
                {/* <h3 className='text-2xl font-bold tracking-tight text-white'>Get early access</h3>
                <p className='text-content-focus mt-2'>
                  Did you sign up to the newsletter? If so, use the keyword we sent you to get access.{' '}
                  <a className='hover:text-primary-focus whitespace-nowrap font-bold text-white' href='##'>
                    Go now<span aria-hidden='true'> &rarr;</span>
                  </a>
                </p> */}
                <div className='flex justify-center space-x-6 md:order-2'>
                  <Link
                    className='text-mask-contrast hover:text-primary-focus'
                    href={'https://www.linkedin.com/company/blyth-workwear/'}
                    prefetch={false}
                    target='_blank'
                  >
                    <span className='sr-only'>{'Linkedin'}</span>
                    <svg aria-hidden='true' className='h-10 w-10' fill='currentColor' viewBox='0 0 448 512'>
                      <path
                        d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'
                        fill='currentColor'
                      />
                    </svg>
                  </Link>
                  <Link
                    className='text-mask-contrast hover:text-primary-focus'
                    href={'https://www.facebook.com/blyth.cz/'}
                    prefetch={false}
                    target='_blank'
                  >
                    <span className='sr-only'>{'Facebook'}</span>
                    <svg aria-hidden='true' className='h-10 w-10' fill='currentColor' viewBox='0 0 24 24'>
                      <path
                        clipRule='evenodd'
                        d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
                        fillRule='evenodd'
                      />
                    </svg>
                  </Link>
                  <Link
                    className='text-mask-contrast hover:text-primary-focus'
                    href={'https://www.instagram.com/blyth.cz/'}
                    prefetch={false}
                    target='_blank'
                  >
                    <span className='sr-only'>{'Instagram'}</span>
                    <svg aria-hidden='true' className='h-10 w-10' fill='currentColor' viewBox='0 0 24 24'>
                      <path
                        clipRule='evenodd'
                        d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'
                        fillRule='evenodd'
                      />
                    </svg>
                  </Link>
                </div>
                <dl className='mt-4 space-y-4'>
                  <dt>
                    <span className='sr-only'>Zákaznická linka</span>
                  </dt>
                  <dd className='text-mask-contrast text-base'>
                    <a className='hover:text-primary-focus flex' href='tel:+420272099544'>
                      <PhoneIcon aria-hidden='true' className='h-6 w-6 shrink-0' />
                      <span className='ml-3'>{'+420 272 099 544'}</span>
                    </a>
                  </dd>
                  <dt>
                    <span className='sr-only'>Email</span>
                  </dt>
                  <dd className='text-mask-contrast text-base'>
                    <a className='hover:text-primary-focus flex' href='mailto:prodej@blyth.cz'>
                      <EnvelopeIcon aria-hidden='true' className='h-6 w-6 shrink-0' />
                      <span className='ml-3'>{'prodej@blyth.cz'}</span>
                    </a>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className='py-4 md:flex md:items-center md:justify-between'>
          <div className='text-center md:text-left'>
            <p className='text-content text-sm'>&copy; {new Date().getFullYear()} Všechna práva vyhrazena</p>
          </div>

          {/* <div className='mt-4 flex items-center justify-center md:mt-0'>
            <div className='flex space-x-8'>
              {footerNavigation.bottomLinks.map((item) => (
                <Link
                  key={item.name}
                  className='text-content hover:text-primary-focus text-sm'
                  href={item.href}
                  prefetch={false}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

function LayoutBaseFooterNewsletterRegisration() {
  const [showThank, setShowThank] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;
    if (showThank === true) {
      timer = setTimeout(() => {
        setShowThank(false);
      }, ms('30s'));
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showThank]);

  const saveNewsletterRegistrationData = trpc.newsletterRegistration.useMutation();

  const handleSaveForm = useCallback(
    (formData: { email: string }) =>
      new Promise((resolve, reject) => {
        saveNewsletterRegistrationData.mutate(
          {
            email: formData.email,
          },
          {
            onError(err) {
              reject(err);
            },
            onSuccess(data) {
              resolve(data);
              setShowThank(true);
            },
          },
        );
      }),
    [saveNewsletterRegistrationData],
  );

  if (showThank === true) {
    return <p>Děkujeme za registraci k odběru novinek.</p>;
  }

  return <LayoutBaseFooterNewsletterRegisrationForm saveForm={handleSaveForm} />;
}

function LayoutBaseFooterNewsletterRegisrationForm({
  saveForm,
}: {
  saveForm: (formData: { email: string }) => Promise<any>;
}) {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
  });

  return (
    <form className='form-styles sm:flex' onSubmit={handleSubmit(saveForm)}>
      <div>
        <label className='sr-only' htmlFor='newsletter-email-address'>
          {'Email adresa'}
        </label>
        <div data-role='input-group'>
          <input
            autoComplete='email'
            id='newsletter-email-address'
            placeholder='ja@priklad.cz'
            type='email'
            {...register('email', {
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/,
              required: true,
            })}
            aria-errormessage={
              errors?.email?.type === 'required'
                ? 'newsletter-email-address-err-required'
                : errors?.email?.type === 'pattern'
                ? 'newsletter-email-address-err-format'
                : undefined
            }
            aria-invalid={errors?.email ? 'true' : 'false'}
          />
          <button className='btn btn-accent w-72' type='submit'>
            {'Odebírat novinky'}
          </button>
        </div>
        {errors.email?.type === 'required' && (
          <p id={'newsletter-email-address-err-required'} role='alert'>
            {'Vyplňte email adresu.'}
          </p>
        )}
        {errors.email?.type === 'pattern' && (
          <p id={'newsletter-email-address-err-format'} role='alert'>
            {'Neplatný formát email adresy.'}
          </p>
        )}
      </div>
    </form>
  );
}
